import * as R from 'ramda';

import { handleActions } from 'redux-actions';
import moment from 'moment';

const selectedStaff = (staff) => R.compose(
  R.keys,
  R.filter(R.equals(true)),
  R.mapObjIndexed(R.prop('isSelected'))
)(staff);

const initialState = {
  data: {
    propositionId: null,
    brokerage: { isSelected: false },
    ccn: null,
    postCode: null,
    startDate: moment().date(1),
    adhesionDate: new Date(),
    staff: null,
    contracts: {},
    contractNumbers: [],
    medicalCheck: false,
    outsandingCheck: false,
    status: 'active',
    background: {
      hasFormerContract: false,
      atmtt: {
        isSelected: false,
        count: 0
      }
    },
    studyAtIndem: false,
    companyInformation: {},
    duplicata: false,
    amendment: {
      isAmendment: false,
      formerContractId: null,
      formerNumberOfContracts: -1,
      formerColleges: [],
      formerContractDescription: {}
    },
    tariffStructure: null
  },
  userRole: [],
  currentStep: 'SELECT_CCN_STEP',
  documents: {},
  fetching: {
    status: null,
    error: false,
    count: 0
  },
  fetchingSendEmail: {
    emailType: null,
    status: null,
    error: null
  },
  sendForSignatureStatus: {
    value: null,
    error: false
  },
  makeDuplicataStatus: {
    value: null,
    error: false
  },
  contractNumbersAreEdited: true,
  relatedContract: {
    checked: false,
    oldLegalForm: '',
    contractNumbers: [],
    closePreviousContract: false
  },
  legalPersonsHasError: false,
  generateContractNumbersLoading: false
};

export const proposition = handleActions({

  PROPOSITION_CONTRACT_NUMBERS_RESET: (state) => {
    return R.assocPath(['data', 'contractNumbers'], [], state);
  },

  PROPOSITION_SET_FETCHING_DOCUMENTS: (state, { payload: { status, error } }) => {
    return R.compose(
      R.assocPath(['fetching', 'status'], status),
      R.assocPath(['fetching', 'error'], error),
    )(state);
  },

  PROPOSITION_FETCHING_COUNTER_INC: (state) => {
    return R.assocPath(['fetching', 'count'], state.fetching.count + 1, state);
  },

  PROPOSITION_FETCHING_RESET: (state) => {
    return R.assoc('fetching', { status: null, error: false, count: 0 }, state);
  },

  PROPOSITION_FETCHING_SEND_EMAIL_STATUS_SET: (state, { payload: { status, error } }) => {
    return R.compose(
      R.assocPath(['fetchingSendEmail', 'status'], status),
      R.assocPath(['fetchingSendEmail', 'error'], error)
    )(state);
  },

  PROPOSITION_FETCHING_SEND_EMAIL_RESET: (state) => {
    return R.assoc('fetchingSendEmail', initialState.fetchingSendEmail, state);
  },

  PROPOSITION_SET_BROKERAGE: (state, { payload: { brokerage } }) => {
    brokerage = R.modify(
      'commercialCode',
      R.when(R.is(Number), R.toString)
    )(brokerage);

    return R.assocPath(['data', 'brokerage'], brokerage, state);
  },

  PROPOSITION_SET_STAFF: (state, { payload: { staff } }) => {
    const currentSelectedStaff = selectedStaff(state.data.staff);

    state = R.assocPath(['data', 'staff'], staff)(state);

    if (!R.isEmpty(R.difference(currentSelectedStaff, selectedStaff(staff)))) {
      state = R.assocPath(['data', 'contractDescription'], {})(state);
    }

    return state;
  },

  PROPOSITION_SET_COMPANY_SIREN: (state, { payload: { siren } }) => {
    return R.assocPath(['data', 'siren'], siren, state);
  },

  PROPOSITION_SET_COMPANY_SIRET: (state, { payload: { siret } }) => {
    return R.assocPath(['data', 'siret'], siret, state);
  },

  PROPOSITION_SET_COMPANY_SIRET_INFO: (state, { payload: { siretInfo } }) => {
    return R.assocPath(['data', 'siretInfo'], siretInfo, state);
  },

  PROPOSITION_SET_EXTRA_ESTABLISHMENTS: (state, { payload: { extraEstablishments } }) => {
    return R.assocPath(['data', 'extraEstablishments'], extraEstablishments, state);
  },

  PROPOSITION_SET_COMPANY_INFORMATION: (state, { payload: { value } }) => {
    R.forEachObjIndexed(
      (v, k) => state = R.assocPath(['data', 'companyInformation', k], v)(state)
    )(value);

    return state;
  },

  PROPOSITION_SET_CONTRACT_DESCRIPTION: (state, { payload: { contractDescription } }) => {
    return R.assocPath(['data', 'contractDescription'], contractDescription, state);
  },

  PROPOSITION_SET_CONTRACT_NUMBERS: (state, { payload: { contractNumbers } }) => {
    return R.assocPath(['data', 'contractNumbers'], contractNumbers)(state);
  },

  PROPOSITION_SET_CONTRACT_NUMBERS_ARE_EDITED: (state, { payload: { contractNumbersAreEdited } }) => {
    return R.assoc('contractNumbersAreEdited', contractNumbersAreEdited)(state);
  },

  PROPOSITION_SET_CURRENT_STEP: (state, { payload: { step } }) => {
    return R.assoc('currentStep', step, state);
  },

  PROPOSITION_SET_DOCUMENTS: (state, { payload: { documents } }) => {
    return R.assoc('documents', documents, state);
  },

  PROPOSITION_SET_LOCATION: (state, { payload: { postCode } }) => {
    return R.assocPath(['data', 'postCode'], postCode, state);
  },

  PROPOSITION_SET_START_DATE: (state, { payload: { startDate } }) => {
    return R.assocPath(['data', 'startDate'], startDate, state);
  },

  PROPOSITION_SET_ADHESION_DATE: (state, { payload: { adhesionDate } }) => {
    return R.assocPath(['data', 'adhesionDate'], adhesionDate, state);
  },

  PROPOSITION_SET_BACKGROUND: (state, { payload: { value } }) => {
    return R.over(R.lensPath(['data', 'background']), R.mergeDeepLeft(value), state);
  },

  PROPOSITION_SET_BACKGROUND_HAS_FORMER_CONTRACT: (state, { payload: { value } }) => {
    return R.assocPath(['data', 'background', 'hasFormerContract'], value, state);
  },

  PROPOSITION_SET_BACKGROUND_ATMTT: (state, { payload: { value } }) => {
    return R.assocPath(['data', 'background', 'atmtt'], value, state);
  },

  PROPOSITION_SET_STUDY_AT_INDEM: (state, { payload: { value } }) => {
    return R.assocPath(['data', 'studyAtIndem'], value, state);
  },

  PROPOSITION_SET_PROPOSITION_ID: (state, { payload: { value } }) => {
    return R.assocPath(['data', 'propositionId'], value, state);
  },

  PROPOSITION_SET_MEDICAL_CHECK: (state, { payload: { value } }) => {
    return R.compose(
      R.assocPath(['data', 'medicalCheck'], value)
    )(state);
  },

  PROPOSITION_SET_DECLARATIVE_ANNEXE: (state, { payload: { value } }) => {
    return R.compose(
      R.assocPath(['data', 'declarativeAnnexe'], value)
    )(state);
  },

  PROPOSITION_SET_OUTSTANDING_ANNEXE: (state, { payload: { value } }) => {
    return R.compose(
      R.assocPath(['data', 'outstandingAnnexe'], value)
    )(state);
  },

  PROPOSITION_SET_DECISION: (state, { payload: { value } }) => {
    return R.assocPath(['data', 'decision'], value, state);
  },

  PROPOSITION_SET_CCN: (state, { payload: { ccn } }) => {
    const ccnHasBothOffers = R.ifElse(
      R.isNil,
      R.always(false),
      R.propSatisfies(
        R.both(
          R.includes('SANTE'),
          R.includes('PREVOYANCE')
        ),
        'offerTypes'
      )
    )(ccn);

    ccn = R.assoc('ccnHasBothOffers', ccnHasBothOffers)(ccn);

    state = R.over(
      R.lensProp('data'),
      R.compose(
        R.assocPath(['brokerage', 'isSelected'], initialState.data.brokerage.isSelected || R.propEq('userRole', 'apporteur', state)),
        R.assoc('staff', initialState.data.staff),
        R.assoc('contractDescriptions', initialState.data.contractDescription),
        R.assoc('ccn', ccn)
      )
    )(state);

    if (R.isEmpty(R.difference(state.data.ccn.PREVOYANCE.colleges, state.data.ccn.PREVOYANCE.bundledCollege))) {
      R.forEach(c => state = R.assocPath(['data', 'staff', c], {
        isSelected: true,
        count: 0
      }, state))(state.data.ccn.PREVOYANCE.colleges);
    }

    return state;
  },

  PROPOSITION_RESET: () => {
    return initialState;
  },

  PROPOSITION_SET_SENT_FOR_SIGNATURE: (state, { payload: { value } }) => {
    return R.assoc('sentForSignature', value, state);
  },

  PROPOSITION_SET_DUPLICATA: (state, { payload: { duplicata } }) => {
    return R.assocPath(['data', 'duplicata'], duplicata, state);
  },

  PROPOSITION_SET_MAKE_DUPLICATA_STATUS: (state, { payload: { value } }) => {
    return R.assoc('makeDuplicataStatus', value, state);
  },

  PROPOSITION_RESET_SEND_MAKE_DUPLICATA_STATUS: (state) => {
    return R.assoc('makeDuplicataStatus', initialState.makeDuplicataStatus, state);
  },

  PROPOSITION_RESUME: (state) => {
    return R.assocPath(['data', 'status'], 'active', state);
  },

  PROPOSITION_SET_STATUS: (state, { payload: { value } }) => {
    return R.assocPath(['data', 'status'], value, state);
  },

  PROPOSITION_SET_SEND_FOR_SIGNATURE_STATUS: (state, { payload: { value } }) => {
    return R.assoc('sendForSignatureStatus', value, state);
  },

  PROPOSITION_RESET_SEND_FOR_SIGNATURE_STATUS: (state) => {
    return R.assoc('sendForSignatureStatus', initialState.sendForSignatureStatus, state);
  },

  PROPOSITION_SET_SIGNATURE: (state, { payload: { signature } }) => {
    return R.assocPath(['data', 'signature'], signature, state);
  },

  PROPOSITION_SET_USER_ROLE: (state, { payload: { value } }) => {
    return R.assoc('userRole', value, state);
  },

  PROPOSITION_SET_OVER_PRICED_RATE: (state, { payload: { overPricedRate } }) => {
    return R.assocPath(['data', 'overPricedRate'], overPricedRate)(state);
  },

  PROPOSITION_SET_CCN_VERSION: (state, { payload: { ccnVersion } }) => {
    return R.assocPath(['data', 'ccnVersion'], ccnVersion)(state);
  },

  PROPOSITION_SET_RELATED_CONTRACT: (state, { payload: { relatedContract } }) => {
    return R.assocPath(['data', 'relatedContract'], relatedContract)(state);
  },

  PROPOSITION_SET_AMENDMENT: (state, { payload: { amendment } }) => {
    return R.assocPath(['data', 'amendment'], amendment)(state);
  },

  PROPOSITION_SET_FIXED_APE: (state, { payload: { fixedAPE } }) => {
    return R.assocPath(['data', 'companyInformation', 'fixedAPE'], fixedAPE)(state);
  },

  PROPOSITION_SET_ORIGIN_CONTRACT_ID: (state, { payload: { originContractId } }) => {
    return R.assocPath(['data', 'originContractId'], originContractId)(state);
  },

  PROPOSITION_SET_GENERATE_CONTRACT_NUMBERS_ERROR: (state, { payload: { value } }) => {
    return R.assoc('generateContractNumbersError', value)(state);
  },

  PROPOSITION_SET_GENERATE_CONTRACT_NUMBERS_LOADING: (state, { payload: { value } }) => {
    return R.assoc('generateContractNumbersLoading', value)(state);
  },

  PROPOSITION_SET_OFFER_TYPES: (state, { payload: { offerTypes } }) => {
    return R.assocPath(['data', 'offerTypes'], offerTypes)(state);
  },

  PROPOSITION_SET_CURRENT_OFFER_TYPE: (state, { payload: { offerType } }) => {
    return R.assocPath(['data', 'currentOfferType'], offerType)(state);
  },

  PROPOSITION_SET_HEALTH: (state, { payload: { health } }) => {
    return R.assocPath(['data', 'health'], health)(state);
  },

  PROPOSITION_SET_HEALTH_CONTRACT_NUMBERS: (state, { payload: { contractNumbers } }) => {
    return R.assocPath(['data', 'health', 'contractNumbers'], contractNumbers)(state);
  },

  PROPOSITION_SET_HEALTH_STAFF: (state, { payload: { staff } }) => {
    const currentSelectedStaff = selectedStaff(R.path(['data', 'health', 'staff'], state));

    state = R.assocPath(['data', 'health', 'staff'], staff)(state);

    if (!R.isEmpty(R.difference(currentSelectedStaff, selectedStaff(staff)))) {
      state = R.assocPath(['data', 'health', 'contractDescription'], {})(state);
    }

    return state;
  },

  PROPOSITION_SET_HEALTH_CONTRACT_DESCRIPTION: (state, { payload: { contractDescription } }) => {
    return R.assocPath(['data', 'health', 'contractDescription'], contractDescription, state);
  },

  PROPOSITION_SET_TARIFF_STRUCTURE: (state, { payload: { tariffStructure } }) => {
    return R.assocPath(['data', 'health', 'tariffStructure'], tariffStructure, state);
  },

  PROPOSITION_SET_TURNOVERS: (state, { payload: { turnovers } }) => {
    return R.assocPath(['data', 'turnovers'], turnovers)(state);
  },

  PROPOSITION_SET_TURNOVER: (state, { payload: { value, contractNumberIndex, college } }) => {
    let turnovers = R.pathOr([], ['data', 'turnovers'], state);

    const turnoverPairIndex = R.findIndex(
      R.whereEq({
        contractNumberIndex,
        college
      })
    )(turnovers);

    if (turnoverPairIndex === -1) {
      turnovers = R.append({
        value,
        contractNumberIndex,
        college
      })(turnovers);
    } else {
      turnovers = R.adjust(
        turnoverPairIndex,
        R.assoc('value', value)
      )(turnovers);
    }

    return R.assocPath(['data', 'turnovers'], turnovers)(state);
  },

  PROPOSITION_SET_HEALTH_TURNOVER: (state, { payload: { value, contractNumberIndex } }) => {
    let turnovers = R.pathOr([], ['data', 'health', 'turnovers'], state);

    const turnoverIndex = R.findIndex(R.propEq('contractNumberIndex', contractNumberIndex))(turnovers);

    if (turnoverIndex === -1) {
      turnovers = R.append({
        value,
        contractNumberIndex,
      })(turnovers);
    } else {
      turnovers = R.adjust(
        turnoverIndex,
        R.assoc('value', value)
      )(turnovers);
    }

    return R.assocPath(['data', 'health', 'turnovers'], turnovers)(state);
  },

  PROPOSITION_SET_LEGAL_PERSONS_HAS_ERROR: (state, { payload: { value } }) => {
    return R.assoc('legalPersonsHasError', value, state);
  },

  PROPOSITION_SET_HEALTH_CCN_VERSION: (state, { payload: { healthCcnVersion } }) => {
    return R.assocPath(['data', 'health', 'ccnVersion'], healthCcnVersion)(state);
  }

}, initialState);

import './BackgroundStep.less';

import * as R from 'ramda';
import React, { useState } from 'react';

import { connect } from 'react-redux';

import {
  propositionSetBackgroundHasFormerContract,
  propositionSetBackgroundAtmtt,
  propositionSetStudyAtIndem,
  propositionGoPreviousStep,
  propositionGoNextStep
} from '../../../reduxActions/proposition';

import withForm from '@thecodeisgreen/withform';

import {
  FieldTitle,
  SelectButtons,
} from '../../../uikit/UIKit';

import {
  BtnNext,
  WorkStoppage
} from '../components';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';

const Background = ({
  atmtt,
  form,
  goPreviousStep,
  goNextStep,
  hasFormerContract,
  studyAtIndem,
  userRole,
  setHasFormerContract,
  setATMTT,
  setStudyATIndem
}) => {
  useKeyPressEnter(onNext, true);

  const [loading, setLoading] = useState(false);

  const onNext = () => {
    try {
      setLoading(true);

      const values = form.values();
      setHasFormerContract(values.hasFormerContract);
      setATMTT({
        isSelected: R.pathOr(false, ['atmtt', 'isSelected'])(values),
        count: parseInt(R.pathOr(0, ['atmtt', 'count'])(values))
      });
      setStudyATIndem(R.pathOr(false, ['atmtt', 'isSelected'])(values) ? values.studyAtIndem : false);
      goNextStep();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <FieldTitle
        text="Aviez-vous un contrat prévoyance précédemment ?"
        onBack={goPreviousStep}
      />
      <div style={{ marginTop: '33px' }}>
        {form.manageField('hasFormerContract', { defaultValue: hasFormerContract })(
          <SelectButtons options={[
            { title: 'Oui', value: true },
            { title: 'Non', value: false },
          ]}/>
        )}
      </div>
      <div style={{ marginTop: '33px' }}>
        <div className="title">{'Avez-vous des salariés en arrêt de travail ou en mi-temps thérapeutique ?'}</div>
        {form.manageField('atmtt', { defaultValue: atmtt })(
          <WorkStoppage/>
        )}
      </div>
      {userRole !== 'groupe_adhesion' && <div style={{ marginTop: '33px', display: R.pathOr(false, ['atmtt', 'isSelected'], form.values()) ? 'block' : 'none' }}>
        <div className="title">{'Souhaitez-vous étudier l\'indemnisation des ces arrêts de travail ?'}</div>
        {form.manageField('studyAtIndem', { defaultValue: studyAtIndem })(
          <SelectButtons options={[
            { title: 'Oui', value: true },
            { title: 'Non', value: false },
          ]}/>
        )}
      </div>}

      <BtnNext
        disabled={false}
        loading={loading}
        onClick={onNext}
        title={'Suivant'}
      />
    </React.Fragment>
  );
};

export default connect(
  ({ proposition }) => ({
    hasFormerContract: R.path(['data', 'background', 'hasFormerContract'], proposition),
    atmtt: R.path(['data', 'background', 'atmtt'], proposition),
    studyAtIndem: R.path(['data', 'studyAtIndem'], proposition),
    userRole: R.prop('userRole', proposition)
  }),
  dispatch => ({
    setHasFormerContract: (value) => dispatch(propositionSetBackgroundHasFormerContract(value)),
    setATMTT: (value) => dispatch(propositionSetBackgroundAtmtt(value)),
    setStudyATIndem: (value) => dispatch(propositionSetStudyAtIndem(value)),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withForm(Background));

/**
 * @flow
 * @relayHash 2904fc08b4bdc946105a818e22d10625
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CivilityEnum = "F" | "M" | "%future added value";
export type CollegeEnum = "AM" | "APP" | "C" | "NC" | "TOUS" | "%future added value";
export type EscAdminTypeEnum = "AUCUN" | "AUTRE" | "SIGNATAIRE" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type PropositionStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type RelatedContractTypeEnum = "PARTIEL" | "TOTAL" | "%future added value";
export type SignatureTypeEnum = "electronic" | "manual" | "%future added value";
export type TariffStructureEnum = "FAMILLE" | "ISOLE" | "UNIFORME" | "%future added value";
export type PropositionQueryVariables = {|
  id?: ?string
|};
export type PropositionQueryResponse = {|
  +data: ?{|
    +departments: ?$ReadOnlyArray<?{|
      +code: ?string,
      +title: ?string,
    |}>
  |},
  +proposition: ?{|
    +id: ?string,
    +originContractId: ?string,
    +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
    +ccnId: ?string,
    +ccnVersion: ?number,
    +ccn: ?{|
      +id: ?string,
      +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
      +title: ?string,
      +shortTitle: ?string,
      +PREVOYANCE: ?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>,
        +mandatoryColleges: ?$ReadOnlyArray<?CollegeEnum>,
        +bundledCollege: ?$ReadOnlyArray<?CollegeEnum>,
        +linkedCollege: ?{|
          +C: ?$ReadOnlyArray<?string>,
          +NC: ?$ReadOnlyArray<?string>,
          +AM: ?$ReadOnlyArray<?string>,
          +APP: ?$ReadOnlyArray<?string>,
          +TOUS: ?$ReadOnlyArray<?string>,
        |},
        +staffMustBeAboveZero: ?$ReadOnlyArray<?string>,
        +atLeastOneStaffMustBeAboveZero: ?$ReadOnlyArray<?string>,
        +collegesLabels: ?{|
          +APP: ?string,
          +AM: ?string,
          +NC: ?string,
          +C: ?string,
        |},
        +collegesOrder: ?$ReadOnlyArray<?CollegeEnum>,
        +displayPopupForColleges: ?{|
          +colleges: ?$ReadOnlyArray<?CollegeEnum>,
          +message: ?string,
        |},
        +maxNumberOfEmployees: ?{|
          +NC: ?number,
          +C: ?number,
          +AM: ?number,
          +APP: ?number,
        |},
        +maxNumberOfTotalEmployees: ?number,
        +mustHaveAtLeastOneOfIfBaseIsSelected: ?{|
          +basesIds: ?$ReadOnlyArray<?string>,
          +selectedBaseId: ?string,
        |},
        +contractDescriptionStepMessage: ?string,
        +conflictingColleges: ?$ReadOnlyArray<?{|
          +colleges: ?$ReadOnlyArray<?CollegeEnum>
        |}>,
        +contractNumbersByCollege: ?boolean,
        +unavailableBefore: ?$ReadOnlyArray<?string>,
        +unavailableAfter: ?{|
          +date: ?string,
          +message: ?string,
        |},
      |},
      +SANTE: ?{|
        +colleges: ?$ReadOnlyArray<?CollegeEnum>,
        +mandatoryColleges: ?$ReadOnlyArray<?CollegeEnum>,
        +bundledCollege: ?$ReadOnlyArray<?CollegeEnum>,
        +linkedCollege: ?{|
          +C: ?$ReadOnlyArray<?string>,
          +NC: ?$ReadOnlyArray<?string>,
          +AM: ?$ReadOnlyArray<?string>,
          +TOUS: ?$ReadOnlyArray<?string>,
          +APP: ?$ReadOnlyArray<?string>,
        |},
        +meltedColleges: ?{|
          +colleges: ?$ReadOnlyArray<?CollegeEnum>,
          +targetCollege: ?CollegeEnum,
        |},
        +conflictingColleges: ?$ReadOnlyArray<?{|
          +colleges: ?$ReadOnlyArray<?CollegeEnum>
        |}>,
        +contractNumbersByCollege: ?boolean,
        +staffMustBeAboveZero: ?$ReadOnlyArray<?string>,
        +atLeastOneStaffMustBeAboveZero: ?$ReadOnlyArray<?string>,
        +collegesLabels: ?{|
          +AM: ?string,
          +NC: ?string,
          +C: ?string,
          +APP: ?string,
        |},
        +collegesOrder: ?$ReadOnlyArray<?CollegeEnum>,
        +displayPopupForColleges: ?{|
          +colleges: ?$ReadOnlyArray<?CollegeEnum>,
          +message: ?string,
        |},
        +maxNumberOfEmployees: ?{|
          +NC: ?number,
          +C: ?number,
          +AM: ?number,
          +APP: ?number,
        |},
        +maxNumberOfTotalEmployees: ?number,
        +mustHaveAtLeastOneOfIfBaseIsSelected: ?{|
          +basesIds: ?$ReadOnlyArray<?string>,
          +selectedBaseId: ?string,
        |},
        +garantiesRdgUrl: ?string,
        +cotisationsRdgUrl: ?string,
        +contractDescriptionStepMessage: ?string,
        +unavailableBefore: ?$ReadOnlyArray<?string>,
        +unavailableAfter: ?{|
          +date: ?string,
          +message: ?string,
        |},
        +insurer: ?string,
      |},
      +customerDetailsOptional: ?boolean,
      +idcc: ?string,
      +codeBrochure: ?string,
      +visionServiceUrl: ?string,
      +naf: ?$ReadOnlyArray<?string>,
      +hasParitarism: ?boolean,
      +unavailableBefore: ?$ReadOnlyArray<?string>,
      +isPack: ?boolean,
      +withClassicNumberOfContracts: ?boolean,
      +forbiddenDepartments: ?$ReadOnlyArray<?string>,
      +amendmentsSettings: ?{|
        +canUnselectOrigin: ?boolean,
        +collegesMustHaveBase: ?$ReadOnlyArray<?CollegeEnum>,
        +collegesMustHaveZeroEmployees: ?$ReadOnlyArray<?CollegeEnum>,
      |},
      +relatedContractsSettings: ?{|
        +contractNumbersCheckDisabled: ?boolean
      |},
      +brokerageSettings: ?{|
        +bundledCollege: ?$ReadOnlyArray<?CollegeEnum>,
        +unavailableBefore: ?string,
      |},
      +brokerageOnly: ?boolean,
      +tariffStructures: ?$ReadOnlyArray<?string>,
    |},
    +contractNumbers: ?$ReadOnlyArray<?string>,
    +background: ?{|
      +hasFormerContract: ?boolean,
      +atmtt: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
    |},
    +brokerage: ?{|
      +isSelected: ?boolean,
      +commercialCode: ?string,
      +commercialCodeUserEmail: ?string,
      +provider: ?string,
    |},
    +postCode: ?string,
    +startDate: ?string,
    +adhesionDate: ?string,
    +propositionNumber: ?number,
    +siren: ?string,
    +siret: ?string,
    +siretInfo: ?{|
      +siret: ?string,
      +siren: ?string,
      +name: ?string,
      +isHeadOffice: ?boolean,
      +nic: ?string,
      +naf: ?string,
      +ape: ?string,
      +legalForm: ?string,
      +manual: ?boolean,
      +address: ?{|
        +city: ?string,
        +postCode: ?string,
        +fields: ?$ReadOnlyArray<?string>,
      |},
      +codeApplication: ?string,
      +identifiantDansSilo: ?string,
    |},
    +extraEstablishments: ?$ReadOnlyArray<?{|
      +name: ?string,
      +siren: ?string,
      +siret: ?string,
      +isHeadOffice: ?boolean,
      +nic: ?string,
      +naf: ?string,
      +ape: ?string,
      +legalForm: ?string,
      +manual: ?boolean,
      +address: ?{|
        +city: ?string,
        +postCode: ?string,
        +fields: ?$ReadOnlyArray<?string>,
      |},
      +identifiantDansSilo: ?string,
      +codeApplication: ?string,
    |}>,
    +status: ?PropositionStatusEnum,
    +statusHistory: ?$ReadOnlyArray<?{|
      +status: ?PropositionStatusEnum,
      +when: ?string,
      +data: ?{|
        +token: ?string
      |},
    |}>,
    +medicalCheck: ?boolean,
    +declarativeAnnexe: ?string,
    +outstandingAnnexe: ?string,
    +companyInformation: ?{|
      +contactFirstname: ?string,
      +contactLastname: ?string,
      +contactEmail: ?string,
      +contactMobile: ?string,
      +contactRole: ?string,
      +contactRoleCode: ?string,
      +accountantEmail: ?string,
      +sendToAccountant: ?boolean,
      +commercialName: ?string,
      +fixedAPE: ?string,
      +fixedAddress: ?{|
        +isChecked: ?boolean,
        +postCode: ?string,
        +city: ?string,
        +fields: ?$ReadOnlyArray<?string>,
      |},
      +escAdmin: ?{|
        +type: ?EscAdminTypeEnum,
        +civility: ?CivilityEnum,
        +lastName: ?string,
        +firstName: ?string,
        +role: ?string,
        +roleCode: ?string,
        +email: ?string,
        +phoneNumber: ?string,
        +birthDate: ?string,
        +birthCountry: ?string,
        +birthCity: ?string,
        +unicityCheck: ?{|
          +unique: ?boolean
        |},
      |},
    |},
    +staff: ?{|
      +NC: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +APP: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +AM: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +C: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +TOUS: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
    |},
    +contractDescription: ?{|
      +NC: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +APP: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +AM: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +C: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +TOUS: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
    |},
    +duplicata: ?boolean,
    +overPricedRate: ?number,
    +relatedContract: ?{|
      +checked: ?boolean,
      +oldLegalForm: ?string,
      +contractNumbers: ?$ReadOnlyArray<?string>,
      +closePreviousContract: ?boolean,
      +oldSiren: ?string,
      +oldNic: ?string,
      +startDate: ?string,
      +type: ?RelatedContractTypeEnum,
      +hasKBIS: ?boolean,
    |},
    +amendment: ?{|
      +isAmendment: ?boolean,
      +formerContractId: ?string,
      +formerNumberOfContracts: ?number,
      +formerColleges: ?$ReadOnlyArray<?string>,
      +formerContractDescription: ?{|
        +NC: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +APP: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +AM: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +C: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +TOUS: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
      |},
    |},
    +turnovers: ?$ReadOnlyArray<?{|
      +college: ?CollegeEnum,
      +contractNumberIndex: ?number,
      +value: ?number,
    |}>,
    +health: ?{|
      +ccnVersion: ?number,
      +contractDescription: ?{|
        +NC: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +APP: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +AM: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +C: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
        +TOUS: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string
            |}>,
          |}>
        |},
      |},
      +contractNumbers: ?$ReadOnlyArray<?string>,
      +numberOfContracts: ?number,
      +medicalCheck: ?boolean,
      +insurer: ?string,
      +tariffStructure: ?TariffStructureEnum,
      +regime: ?string,
      +staff: ?{|
        +NC: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +APP: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +AM: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +C: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +TOUS: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
      |},
      +turnovers: ?$ReadOnlyArray<?{|
        +college: ?CollegeEnum,
        +contractNumberIndex: ?number,
        +value: ?number,
      |}>,
      +amendment: ?{|
        +isAmendment: ?boolean,
        +formerContractId: ?string,
        +formerNumberOfContracts: ?number,
        +formerColleges: ?$ReadOnlyArray<?string>,
        +formerContractDescription: ?{|
          +NC: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +APP: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +AM: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +C: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
          +TOUS: ?{|
            +bases: ?$ReadOnlyArray<?{|
              +baseId: ?string,
              +options: ?$ReadOnlyArray<?{|
                +optionId: ?string
              |}>,
            |}>
          |},
        |},
      |},
    |},
    +signature: ?{|
      +type: ?SignatureTypeEnum
    |},
  |},
|};
export type PropositionQuery = {|
  variables: PropositionQueryVariables,
  response: PropositionQueryResponse,
|};
*/


/*
query PropositionQuery(
  $id: ID
) {
  data {
    departments {
      code
      title
    }
  }
  proposition(id: $id) {
    id
    originContractId
    offerTypes
    ccnId
    ccnVersion
    ccn {
      id
      offerTypes
      title
      shortTitle
      PREVOYANCE {
        colleges
        mandatoryColleges
        bundledCollege
        linkedCollege {
          C
          NC
          AM
          APP
          TOUS
        }
        staffMustBeAboveZero
        atLeastOneStaffMustBeAboveZero
        collegesLabels {
          APP
          AM
          NC
          C
        }
        collegesOrder
        displayPopupForColleges {
          colleges
          message
        }
        maxNumberOfEmployees {
          NC
          C
          AM
          APP
        }
        maxNumberOfTotalEmployees
        mustHaveAtLeastOneOfIfBaseIsSelected {
          basesIds
          selectedBaseId
        }
        contractDescriptionStepMessage
        conflictingColleges {
          colleges
        }
        contractNumbersByCollege
        unavailableBefore
        unavailableAfter {
          date
          message
        }
      }
      SANTE {
        colleges
        mandatoryColleges
        bundledCollege
        linkedCollege {
          C
          NC
          AM
          TOUS
          APP
        }
        meltedColleges {
          colleges
          targetCollege
        }
        conflictingColleges {
          colleges
        }
        contractNumbersByCollege
        staffMustBeAboveZero
        atLeastOneStaffMustBeAboveZero
        collegesLabels {
          AM
          NC
          C
          APP
        }
        collegesOrder
        displayPopupForColleges {
          colleges
          message
        }
        maxNumberOfEmployees {
          NC
          C
          AM
          APP
        }
        maxNumberOfTotalEmployees
        mustHaveAtLeastOneOfIfBaseIsSelected {
          basesIds
          selectedBaseId
        }
        garantiesRdgUrl
        cotisationsRdgUrl
        contractDescriptionStepMessage
        unavailableBefore
        unavailableAfter {
          date
          message
        }
        insurer
      }
      customerDetailsOptional
      idcc
      codeBrochure
      visionServiceUrl
      naf
      hasParitarism
      unavailableBefore
      isPack
      withClassicNumberOfContracts
      forbiddenDepartments
      amendmentsSettings {
        canUnselectOrigin
        collegesMustHaveBase
        collegesMustHaveZeroEmployees
      }
      relatedContractsSettings {
        contractNumbersCheckDisabled
      }
      brokerageSettings {
        bundledCollege
        unavailableBefore
      }
      brokerageOnly
      tariffStructures
    }
    contractNumbers
    background {
      hasFormerContract
      atmtt {
        isSelected
        count
      }
    }
    brokerage {
      isSelected
      commercialCode
      commercialCodeUserEmail
      provider
    }
    postCode
    startDate
    adhesionDate
    propositionNumber
    siren
    siret
    siretInfo {
      siret
      siren
      name
      isHeadOffice
      nic
      naf
      ape
      legalForm
      manual
      address {
        city
        postCode
        fields
      }
      codeApplication
      identifiantDansSilo
    }
    extraEstablishments {
      name
      siren
      siret
      isHeadOffice
      nic
      naf
      ape
      legalForm
      manual
      address {
        city
        postCode
        fields
      }
      identifiantDansSilo
      codeApplication
    }
    status
    statusHistory {
      status
      when
      data {
        token
      }
    }
    medicalCheck
    declarativeAnnexe
    outstandingAnnexe
    companyInformation {
      contactFirstname
      contactLastname
      contactEmail
      contactMobile
      contactRole
      contactRoleCode
      accountantEmail
      sendToAccountant
      commercialName
      fixedAPE
      fixedAddress {
        isChecked
        postCode
        city
        fields
      }
      escAdmin {
        type
        civility
        lastName
        firstName
        role
        roleCode
        email
        phoneNumber
        birthDate
        birthCountry
        birthCity
        unicityCheck {
          unique
        }
      }
    }
    staff {
      NC {
        isSelected
        count
      }
      APP {
        isSelected
        count
      }
      AM {
        isSelected
        count
      }
      C {
        isSelected
        count
      }
      TOUS {
        isSelected
        count
      }
    }
    contractDescription {
      NC {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      APP {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      AM {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      C {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      TOUS {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
    }
    duplicata
    overPricedRate
    relatedContract {
      checked
      oldLegalForm
      contractNumbers
      closePreviousContract
      oldSiren
      oldNic
      startDate
      type
      hasKBIS
    }
    amendment {
      isAmendment
      formerContractId
      formerNumberOfContracts
      formerColleges
      formerContractDescription {
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
    }
    turnovers {
      college
      contractNumberIndex
      value
    }
    health {
      ccnVersion
      contractDescription {
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
      contractNumbers
      numberOfContracts
      medicalCheck
      insurer
      tariffStructure
      regime
      staff {
        NC {
          isSelected
          count
        }
        APP {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        C {
          isSelected
          count
        }
        TOUS {
          isSelected
          count
        }
      }
      turnovers {
        college
        contractNumberIndex
        value
      }
      amendment {
        isAmendment
        formerContractId
        formerNumberOfContracts
        formerColleges
        formerContractDescription {
          NC {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          APP {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          C {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          TOUS {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
        }
      }
    }
    signature {
      type
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerTypes",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ccnVersion",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "colleges",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandatoryColleges",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundledCollege",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "C",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "NC",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AM",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "APP",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "TOUS",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "staffMustBeAboveZero",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "atLeastOneStaffMustBeAboveZero",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collegesOrder",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnDisplayPopupForColleges",
  "kind": "LinkedField",
  "name": "displayPopupForColleges",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnEmployeesColleges",
  "kind": "LinkedField",
  "name": "maxNumberOfEmployees",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v8/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxNumberOfTotalEmployees",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "MustHaveAtLeastOneOfIfBaseIsSelected",
  "kind": "LinkedField",
  "name": "mustHaveAtLeastOneOfIfBaseIsSelected",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basesIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedBaseId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractDescriptionStepMessage",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ConflictingColleges",
  "kind": "LinkedField",
  "name": "conflictingColleges",
  "plural": true,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbersByCollege",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unavailableBefore",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnUnavailableAfter",
  "kind": "LinkedField",
  "name": "unavailableAfter",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v16/*: any*/)
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insurer",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "naf",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbers",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSelected",
  "storageKey": null
},
v30 = [
  (v29/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postCode",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siren",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siret",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHeadOffice",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nic",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ape",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legalForm",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manual",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fields",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    (v41/*: any*/),
    (v31/*: any*/),
    (v42/*: any*/)
  ],
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codeApplication",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifiantDansSilo",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicalCheck",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "StaffFigures",
  "kind": "LinkedField",
  "name": "staff",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "NC",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "APP",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "AM",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "C",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "TOUS",
      "plural": false,
      "selections": (v30/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v50 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BaseProduct",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseOptionProduct",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v51 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "NC",
    "plural": false,
    "selections": (v50/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "APP",
    "plural": false,
    "selections": (v50/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "AM",
    "plural": false,
    "selections": (v50/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "C",
    "plural": false,
    "selections": (v50/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "TOUS",
    "plural": false,
    "selections": (v50/*: any*/),
    "storageKey": null
  }
],
v52 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDescription",
  "kind": "LinkedField",
  "name": "contractDescription",
  "plural": false,
  "selections": (v51/*: any*/),
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "concreteType": "Amendment",
  "kind": "LinkedField",
  "name": "amendment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAmendment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formerContractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formerNumberOfContracts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formerColleges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractDescription",
      "kind": "LinkedField",
      "name": "formerContractDescription",
      "plural": false,
      "selections": (v51/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "concreteType": "Turnover",
  "kind": "LinkedField",
  "name": "turnovers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "college",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractNumberIndex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v55 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Department",
        "kind": "LinkedField",
        "name": "departments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Proposition",
    "kind": "LinkedField",
    "name": "proposition",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "originContractId",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnId",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Ccn",
        "kind": "LinkedField",
        "name": "ccn",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferTypeInformations",
            "kind": "LinkedField",
            "name": "PREVOYANCE",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CcnLinkedCollege",
                "kind": "LinkedField",
                "name": "linkedCollege",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CcnLabelsColleges",
                "kind": "LinkedField",
                "name": "collegesLabels",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v10/*: any*/),
                  (v9/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferTypeInformations",
            "kind": "LinkedField",
            "name": "SANTE",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CcnLinkedCollege",
                "kind": "LinkedField",
                "name": "linkedCollege",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MeltedColleges",
                "kind": "LinkedField",
                "name": "meltedColleges",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetCollege",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              (v23/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CcnLabelsColleges",
                "kind": "LinkedField",
                "name": "collegesLabels",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v9/*: any*/),
                  (v8/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "garantiesRdgUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cotisationsRdgUrl",
                "storageKey": null
              },
              (v21/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerDetailsOptional",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "idcc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "codeBrochure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visionServiceUrl",
            "storageKey": null
          },
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasParitarism",
            "storageKey": null
          },
          (v24/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPack",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "withClassicNumberOfContracts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forbiddenDepartments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CcnAmendmentsSettings",
            "kind": "LinkedField",
            "name": "amendmentsSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canUnselectOrigin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collegesMustHaveBase",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collegesMustHaveZeroEmployees",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CcnRelatedContractSettings",
            "kind": "LinkedField",
            "name": "relatedContractsSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractNumbersCheckDisabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CcnBrokerageSettings",
            "kind": "LinkedField",
            "name": "brokerageSettings",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v24/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brokerageOnly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tariffStructures",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v28/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Background",
        "kind": "LinkedField",
        "name": "background",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasFormerContract",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BackgroundAtmtt",
            "kind": "LinkedField",
            "name": "atmtt",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Brokerage",
        "kind": "LinkedField",
        "name": "brokerage",
        "plural": false,
        "selections": [
          (v29/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commercialCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commercialCodeUserEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v31/*: any*/),
      (v32/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adhesionDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propositionNumber",
        "storageKey": null
      },
      (v33/*: any*/),
      (v34/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SiretInfo",
        "kind": "LinkedField",
        "name": "siretInfo",
        "plural": false,
        "selections": [
          (v34/*: any*/),
          (v33/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v27/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SiretInfo",
        "kind": "LinkedField",
        "name": "extraEstablishments",
        "plural": true,
        "selections": [
          (v35/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v27/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v43/*: any*/),
          (v45/*: any*/),
          (v44/*: any*/)
        ],
        "storageKey": null
      },
      (v46/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "StatusHistory",
        "kind": "LinkedField",
        "name": "statusHistory",
        "plural": true,
        "selections": [
          (v46/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "when",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusHistoryData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v47/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "declarativeAnnexe",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "outstandingAnnexe",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyInformation",
        "kind": "LinkedField",
        "name": "companyInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactFirstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactLastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactMobile",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactRoleCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountantEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sendToAccountant",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commercialName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixedAPE",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FixedAddress",
            "kind": "LinkedField",
            "name": "fixedAddress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isChecked",
                "storageKey": null
              },
              (v31/*: any*/),
              (v41/*: any*/),
              (v42/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EscAdmin",
            "kind": "LinkedField",
            "name": "escAdmin",
            "plural": false,
            "selections": [
              (v48/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "civility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthCountry",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthCity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UnicityCheck",
                "kind": "LinkedField",
                "name": "unicityCheck",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unique",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v49/*: any*/),
      (v52/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duplicata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "overPricedRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RelatedContract",
        "kind": "LinkedField",
        "name": "relatedContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oldLegalForm",
            "storageKey": null
          },
          (v28/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closePreviousContract",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oldSiren",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oldNic",
            "storageKey": null
          },
          (v32/*: any*/),
          (v48/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasKBIS",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v53/*: any*/),
      (v54/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractHealth",
        "kind": "LinkedField",
        "name": "health",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v52/*: any*/),
          (v28/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfContracts",
            "storageKey": null
          },
          (v47/*: any*/),
          (v26/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tariffStructure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regime",
            "storageKey": null
          },
          (v49/*: any*/),
          (v54/*: any*/),
          (v53/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Signature",
        "kind": "LinkedField",
        "name": "signature",
        "plural": false,
        "selections": [
          (v48/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropositionQuery",
    "selections": (v55/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropositionQuery",
    "selections": (v55/*: any*/)
  },
  "params": {
    "id": "2904fc08b4bdc946105a818e22d10625",
    "metadata": {},
    "name": "PropositionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0466ab0575943e69ce25d3f18f6c05c5';

module.exports = node;

import * as R from 'ramda';
import { DragDropContext } from 'react-beautiful-dnd';
import React, { useState, useEffect } from 'react';
import EstablishmentDroppable from './components/EstablishmentDroppable';
import { Button } from '../../../../uikit/UIKit';
import { TextInput } from '../../../../uikit';
import withForm from '@thecodeisgreen/withform';
import { connect } from 'react-redux';
import { propositionSetFixedApe } from '../../../../reduxActions/proposition';
import useKeyPressEnter from '../../../../hooks/useKeyPressEnter';
import useCheckSiretsAreUsed from '../../../../hooks/useCheckSiretsAreUsed';
import { libs, validations } from '@fasstech/spid-front';
import LegalPersonsError from '../../steps/companyEstablishmentStep/LegalPersonsError';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getHeadOffice = (selectedEstablishments) => {
  let headOffice = undefined;
  R.forEach(establishment => {
    if (establishment.isHeadOffice) headOffice = establishment;
  })(selectedEstablishments);

  if (R.isNil(headOffice)) headOffice = selectedEstablishments[0];

  return headOffice;
};

const getSelectedEstablishments = (selectedEstablishments) => {
  const hasHeadOffice = R.reduce((hasHeadOffice, establishment) => hasHeadOffice || establishment.isHeadOffice, false)(selectedEstablishments);
  if (!hasHeadOffice) selectedEstablishments.splice(0, 1);

  return R.reduce((selected, establishment) => {
    if (!establishment.isHeadOffice) selected.push(establishment);
    return selected;
  }, [])(selectedEstablishments);
};

const useDragAndDrop = ({
  foundEstablishments,
  selectedEstablishments,
  establishments,
  siretInfo,
  extraEstablishments,
  setSelectedEstablishments,
  setFoundEstablishments,
  duplicata,
  resetLegalPersonsHasError
}) => {
  useEffect(() => {
    let selectedSirets = [], foundSirets = [];
    let newFoundEstablishments = foundEstablishments, newSelectedEstablishments = selectedEstablishments;

    const getSirets = (source, destination) => {
      R.forEach(establishment => destination.push(establishment.siret))(source);
      return destination;
    };
    foundSirets = getSirets(newFoundEstablishments, foundSirets);

    const pushEstablishmentsIntoFound = () => {
      R.forEach(establishment => {
        if (!R.includes(establishment.siret)(selectedSirets.concat(foundSirets))) newFoundEstablishments.push(establishment);
      })(establishments);
    };
    const pushSelectedIntoNewSelected = () => {
      R.forEach(selectedEstablishment => {
        if (!R.includes(selectedEstablishment.siret)(selectedSirets)) newSelectedEstablishments.push(selectedEstablishment);
        selectedSirets.push(selectedEstablishment.siret);
      })(selectedEstablishments);
    };

    if (!R.isNil(siretInfo) && !R.isNil(siretInfo.siret)) {
      newSelectedEstablishments = [siretInfo].concat(extraEstablishments);
      selectedSirets = getSirets(newSelectedEstablishments, selectedSirets);
      pushSelectedIntoNewSelected();
      pushEstablishmentsIntoFound();
    }
    else {
      if (R.length(newFoundEstablishments) === 0 && R.length(newSelectedEstablishments) === 0) newFoundEstablishments = establishments;
      else {
        selectedSirets = getSirets(newSelectedEstablishments, selectedSirets);
        pushEstablishmentsIntoFound();
      }
    }

    if (R.length(newFoundEstablishments) === 1 && R.length(newSelectedEstablishments) === 0) {
      setSelectedEstablishments(newFoundEstablishments);
    }
    else {
      setFoundEstablishments(newFoundEstablishments);
      setSelectedEstablishments(newSelectedEstablishments);
    }
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    resetLegalPersonsHasError();

    if (source.droppableId === destination.droppableId) {

      if (source.droppableId === 'foundEstablishments') setFoundEstablishments(reorder(foundEstablishments, source.index, destination.index));
      else setSelectedEstablishments(reorder(selectedEstablishments, source.index, destination.index));

    } else {

      if (destination.droppableId === 'selectedEstablishments' && duplicata && R.length(selectedEstablishments) === 1) return;

      const result = move(
        source.droppableId === 'foundEstablishments' ? foundEstablishments : selectedEstablishments,
        destination.droppableId === 'foundEstablishments' ? foundEstablishments : selectedEstablishments,
        source,
        destination
      );

      setFoundEstablishments(result.foundEstablishments);
      setSelectedEstablishments(result.selectedEstablishments);

    }
  };

  const onAction = (droppableId, index) => {
    const found = Array.from(foundEstablishments);
    const selected = Array.from(selectedEstablishments);

    if (droppableId === 'foundEstablishments') {
      if (duplicata && R.length(selectedEstablishments) === 1) return;
      selected.push(found[index]);
      found.splice(index, 1);
    } else {
      found.push(selected[index]);
      selected.splice(index, 1);
    }

    resetLegalPersonsHasError();
    setFoundEstablishments(found);
    setSelectedEstablishments(selected);
  };

  return {
    onAction,
    onDragEnd
  };
};

const useCompanyEstablishment = ({
  fixedAPE,
  onSelect,
  setFixedAPE,
  form,
  selectedEstablishments,
  ccn,
  ccnId,
  contractId,
  isAmendment,
  duplicata,
  legalPersonsHasError
}) => {
  const [fixAPE, setFixAPE] = useState(!R.isNil(fixedAPE));
  const [usedSirets, setUsedSirets] = useState([]);
  const [departmentIsForbidden, setDepartmentIsForbidden] = useState(false);
  const checkSiretsAreUsed = useCheckSiretsAreUsed();

  const forbiddenDepartments = R.propOr([], 'forbiddenDepartments', ccn);

  useEffect(() => {
    setDepartmentIsForbidden(false);
  }, [selectedEstablishments]);

  const onNext = () => {
    const _onNext = () => {
      onSelect(getHeadOffice(selectedEstablishments), getSelectedEstablishments(selectedEstablishments));
      if (fixAPE) {
        setFixedAPE(R.prop('fixedAPE', form.values()));
      } else {
        setFixedAPE(undefined);
      }
    };

    const sirets = R.pluck('siret', selectedEstablishments);

    const establishmentsAreForbidden = R.isEmpty(forbiddenDepartments) ? false : R.compose(
      filteredForbiddenDepartments => R.length(filteredForbiddenDepartments) < R.length(forbiddenDepartments),
      R.difference(forbiddenDepartments),
      R.map(libs.getPostCode),
      R.uniq,
      R.map(R.path(['address', 'postCode']))
    )(selectedEstablishments);

    if (establishmentsAreForbidden) {
      setDepartmentIsForbidden(true);
    } else {
      if (isAmendment || duplicata) {
        _onNext();
      } else {
        checkSiretsAreUsed({ sirets, ccnId, contractId }, siretsAreUsed => {
          const usedSirets = R.compose(
            R.pluck('siret'),
            R.filter(R.propEq('isUsed', true))
          )(siretsAreUsed);

          setUsedSirets(usedSirets);

          if (R.isEmpty(usedSirets)) {
            _onNext();
          }
        });
      }
    }
  };

  const releaseStatusIsPartial = R.compose(
    R.any(R.propEq('releaseStatusIsPartial', true)),
    R.defaultTo([])
  )(selectedEstablishments);

  const buttonDisabled = R.length(selectedEstablishments) === 0 || (fixAPE && !form.isValid()) || legalPersonsHasError || releaseStatusIsPartial;

  useKeyPressEnter(onNext, !buttonDisabled);

  return {
    fixAPE,
    setFixAPE,
    onNext,
    buttonDisabled,
    usedSirets,
    departmentIsForbidden,
    releaseStatusIsPartial
  };
};

const CompanyEstablishmentDragAndDrop = ({
  ccnId,
  ccn,
  contractId,
  duplicata,
  disabled,
  establishments,
  extraEstablishments,
  fixedAPE,
  form,
  foundEstablishments,
  isAmendment,
  onSelect,
  selectedEstablishments,
  setFixedAPE,
  setFoundEstablishments,
  setManualState,
  setSelectedEstablishments,
  siretInfo,
  legalPersonsHasError,
  resetLegalPersonsHasError
}) => {
  const { onAction, onDragEnd } = useDragAndDrop({
    foundEstablishments,
    selectedEstablishments,
    establishments,
    siretInfo,
    extraEstablishments,
    setSelectedEstablishments,
    setFoundEstablishments,
    duplicata,
    resetLegalPersonsHasError
  });

  const {
    fixAPE,
    setFixAPE,
    onNext,
    buttonDisabled,
    usedSirets,
    departmentIsForbidden,
    releaseStatusIsPartial
  } = useCompanyEstablishment({
    ccnId,
    ccn,
    contractId,
    fixedAPE,
    onSelect,
    setFixedAPE,
    form,
    selectedEstablishments,
    isAmendment,
    duplicata,
    legalPersonsHasError
  });

  return <DragDropContext onDragEnd={(result) => onDragEnd(result)} className="establishment-dragndrop">

    <div style={{ display: 'inline-flex' }}>
      <EstablishmentDroppable
        droppableId="foundEstablishments"
        establishments={foundEstablishments}
        title="ÉTABLISSEMENTS TROUVÉS"
        actionLabel="Sélectionner"
        onAction={onAction}
        textColor="black"
        disabled={disabled}
      />
      <EstablishmentDroppable
        droppableId="selectedEstablishments"
        establishments={selectedEstablishments}
        title="ÉTABLISSEMENTS SÉLECTIONNÉS"
        actionLabel="Désélectionner"
        onAction={onAction}
        textColor="grey"
        disabled={disabled}
      />
    </div>

    {duplicata && R.length(establishments) > 1 && <span><br/>{'Ce contrat étant un duplicata, vous ne pouvez sélectionner qu\'un seul établissement. '}</span>}

    {!R.isEmpty(usedSirets) && !duplicata && (
      <div style={{ color: 'red' }}>
        Une souscription avec {R.length(usedSirets) === 1 ? 'le SIRET suivant' : 'les SIRETs suivants'} est déjà en cours sur SPID : {R.join(', ', usedSirets)}.
      </div>
    )}

    {legalPersonsHasError && <LegalPersonsError/>}

    <div style={{ float: 'right' }}>
      <Button
        onClick={() => setManualState('isAdding')}
        disabled={(duplicata && R.length(selectedEstablishments) > 0) || disabled}
        size="small"
      >
        Ajouter un établissement manuellement
      </Button>

      <div style={{ display: 'inline-flex' }}>
        <Button
          size="small"
          style={{ margin: '10px 0' }}
          onClick={() => setFixAPE(!fixAPE)}
        >
          Déroger le code APE
        </Button>

        {form.manageField(
          'fixedAPE', {
            defaultValue: fixedAPE,
            show: () => fixAPE,
            isValid: validations.apeIsValid,
            styleOnNotValid: { borderColor: 'orange' },
            isUpdated: R.toUpper
          }
        )(<TextInput
          size="small"
          placeholder="7010Z"
          style={{ width: '70px', marginLeft: '5px' }}
        />)}
      </div>
    </div>

    {departmentIsForbidden && <div className="text-red-500">{'Une souscription pour ce département n\'est pas autorisée.'}</div>}
    {releaseStatusIsPartial && <div className="text-red-500">{'Entreprise en diffusion partielle, vous ne pouvez pas souscrire via SPID. Merci d’utiliser le trames du RDG.'}</div>}

    <Button
      onClick={onNext}
      disabled={buttonDisabled}
    >
      Valider la sélection
    </Button>
  </DragDropContext>;
};

export default connect(
  state => ({
    ccn: R.path(['proposition', 'data', 'ccn'], state),
    fixedAPE: R.pathOr(undefined, ['proposition', 'data', 'companyInformation', 'fixedAPE'], state),
    ccnId: R.path(['proposition', 'data', 'ccn', 'id'], state),
    contractId: R.path(['proposition', 'data', 'propositionId'], state),
    isAmendment: R.pathEq(['proposition', 'data', 'amendment', 'isAmendment'], true, state)
  }),
  dispatch => ({
    setFixedAPE: fixedAPE => dispatch(propositionSetFixedApe(fixedAPE))
  })
)(withForm(CompanyEstablishmentDragAndDrop));

import * as R from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  propositionConfirmContract,
  propositionSendForSignature,
  propositionSaveClientEmail,
  propositionCloseContract, propositionCloseAndDuplicateContract
} from '../../../reduxActions/proposition';
import {
  Button,
  TextInput,
  FieldTitle,
  RedirectWithDelay
} from '../../../uikit';
import withUser from '../../../withUser';
import { validations } from '@fasstech/spid-front';
import withConfig from '../../../withConfig';

const Separation = () => <div style={{ height: '2px', backgroundColor: '#AAAAAA', width: '85%', margin: '40px auto' }}/>;

const EmailStatus = ({ sendForSignatureStatus, sendForSignatureError }) => (
  <div style={{ marginLeft: '8px', marginTop: '16px', fontSize: '12px', color: '#444"' }}>
    {sendForSignatureError && (
      <div className="error">{'Nous avons recontré un problème.'}</div>
    )}

    {!R.isNil(sendForSignatureStatus) && sendForSignatureStatus === 'conversion_failed' && (
      <div className="error">{'La conversion des documents a échoué.'}</div>
    )}

    {sendForSignatureError === 'user_must_be_connected' && (
      <div className="error">
        <RedirectWithDelay
          delay={10000}
          message="Votre session a expiré, vous allez être redirigé vers la page de connexion"
          href="/account/signin"
        />
      </div>
    )}

    {!sendForSignatureError && !R.isNil(sendForSignatureStatus) && sendForSignatureStatus !== 'email_sent_to_user' && sendForSignatureStatus !== 'conversion_failed' && (
      <div>{'Préparation de l\'envoi.'}</div>
    )}

    {!sendForSignatureError && sendForSignatureStatus === 'email_sent_to_user' && (
      <div>{'L\'email a été envoyé.'}</div>
    )}

    {sendForSignatureError === 'error: contract numbers already used' && (
      <div>Le(s) numéro(s) de contrat est(sont) déjà utilisé(s) dans une proposition en cours ou signée.</div>
    )}

    {sendForSignatureError === 'error: siret already pending or confirmed' && (
      <div>Le numéro SIREN est déjà utilisé dans une proposition en cours ou signée.</div>
    )}
  </div>
);

const ConfirmContractStep = ({
  closeContract,
  confirmContract,
  contactEmail,
  saveClientMail,
  sendElectronicMode,
  sendForSignatureError,
  sendForSignatureStatus,
  sendManualMode,
  status,
  config,
  user,
  closeAndDuplicateContract,
  isElectronicSignature
}) => {
  const [editing, setEditing] = useState(false);
  const [newClientEmail, setNewClientEmail] = useState(contactEmail);

  const onSaveAddress = () => {
    saveClientMail(newClientEmail);
    setEditing(false);
  };

  const allowSignatureDelay = !R.propOr(false, 'disableSignatureDelay', config) && R.both(
    R.propEq('role', 'reseau_commercial'),
    R.either(
      R.pathEq(['extra', 'sales'], 'VAD'),
      R.pathEq(['extra', 'sales'], 'VADC'),
    )
  )(user);

  const isClosed = status === 'closed';

  return <>

    <FieldTitle
      text="Mettre en gestion"
    />

    {!isElectronicSignature && <>
      <div className="confirm-contract-title">
        {'Vérifiez que vous avez bien reçu l\'ensemble des documents. Si c\'est le cas vous pouvez mettre le contrat en gestion.'}
      </div>
      <Button style={{ display: 'inline-block', marginTop: '10px' }} onClick={confirmContract} disabled={isClosed}>Mettre en gestion</Button>

      <Separation/>
    </>}

    <div className="confirm-contract-title">
      {'Vous souhaitez modifier l\'offre et renvoyer l\'email de signature au client : le précédent email envoyé au client sera annulé.'}
    </div>

    <Button
      disabled={isClosed}
      style={{ display: 'inline-block', marginTop: '10px' }}
      size="medium"
      onClick={closeAndDuplicateContract}
    >
      {'Réouvrir la proposition et renvoyer l\'email de signature'}
    </Button>

    <Separation/>

    <div className="confirm-contract-title">
      {'Votre client n\'a pas reçu le mail de signature, vous pouvez lui renvoyer :'}
    </div>

    <div className="field field-text-input" style={{ margin: '15px 0 5px 0' }}>
      <TextInput
        disabled={!editing}
        placeholder="Email du destinataire"
        type="email" size="small"
        style={{ width: '480px', borderColor: validations.emailIsValid(newClientEmail) ? '' : 'orange' }}
        value={newClientEmail}
        onChange={(e) => setNewClientEmail(e.target.value)}
      />
    </div>

    <Button
      disabled={editing || isClosed}
      onClick={() => setEditing(true)}
      size="small"
      style={{ display: 'inline-block' }}
    >
      Modifier
    </Button>

    <Button
      disabled={!validations.emailIsValid(newClientEmail) || isClosed}
      onClick={onSaveAddress}
      size="small"
      style={{ display: editing ? 'inline-block' : 'none' }}
    >
      Enregistrer la nouvelle adresse
    </Button>

    <br/>

    <Button
      disabled={editing || isClosed}
      size="medium"
      style={{ display: 'inline-block', marginTop: '30px' }}
      onClick={() => sendManualMode()}
    >
      {'Renvoyer le mail de signature manuelle'}
    </Button>

    <Button
      disabled={editing || isClosed}
      size="medium"
      style={{ display: 'inline-block', marginTop: '30px' }}
      onClick={() => sendElectronicMode()}
    >
      {'Renvoyer le mail de signature électronique'}
    </Button>

    {allowSignatureDelay && <Button
      disabled={editing || isClosed}
      size="medium"
      style={{ display: 'inline-block', marginTop: '8px' }}
      onClick={() => sendElectronicMode('delayed')}
    >
      {'Renvoyer le mail de signature DEM TEL 24H'}
    </Button>}

    <EmailStatus
      sendForSignatureError={sendForSignatureError}
      sendForSignatureStatus={sendForSignatureStatus}
    />

    <Separation/>

    <div className="confirm-contract-title">
      {'Vous pouvez également clôturer le contrat sans suite :'}
    </div>

    <Button
      size="medium"
      onClick={closeContract}
      disabled={isClosed}
    >
      Annuler la souscription
    </Button>

    {isClosed && <span style={{ marginTop: '10px', fontWeight: 'bold' }}>Le contrat a été clôturé.</span>}
  </>;
};

export default withUser(withConfig(connect(
  (state) => {
    const proposition = R.propOr({}, 'proposition')(state);

    return {
      sendForSignatureStatus: R.path(['sendForSignatureStatus', 'value'], proposition),
      sendForSignatureError: R.path(['sendForSignatureStatus', 'error'], proposition),
      contactEmail: R.pathOr('', ['data', 'companyInformation', 'contactEmail'])(proposition),
      status: R.pathOr('', ['data', 'status'])(proposition),
      isElectronicSignature: R.pathEq(['data', 'signature', 'type'], 'electronic')(proposition)
    };
  },
  (dispatch) => ({
    closeContract: () => dispatch(propositionCloseContract('CLOSED_BY_MAKER')),
    confirmContract: () => dispatch(propositionConfirmContract()),
    sendManualMode: () => dispatch(propositionSendForSignature('manual')),
    sendElectronicMode: mode => dispatch(propositionSendForSignature('electronic', mode)),
    saveClientMail: (email) => dispatch(propositionSaveClientEmail(email)),
    closeAndDuplicateContract: () => dispatch(propositionCloseAndDuplicateContract())
  })
)(ConfirmContractStep)));

import * as R from 'ramda';
import './PendingPropositionStep.less';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import withForm from '@thecodeisgreen/withform';
import classNames from 'classnames';
import {
  propositionSetDecision,
  propositionGoNextStep,
  propositionSaveOverPricedRate,
  propositionSetCurrentOfferType
} from '../../../reduxActions/proposition';
import { compose, withHandlers, withProps } from 'recompose';
import { FieldTitle, TextInput, UploadButton } from '../../../uikit/UIKit';
import { BtnNext } from '../components';
import { validations } from '@fasstech/spid-front';
import withUser from '../../../withUser';
import { libs } from '@fasstech/spid-front';

const status = (values) => {
  const { propositionAccepted, medicalCheckResponse, medicalFeedbackFile, declarativeAnnexeFile, outstandingAnnexeFile } = values;

  let v = {
    medicalFeedbackFile,
    declarativeAnnexeFile,
    outstandingAnnexeFile,
    resumeProposition: !R.isNil(propositionAccepted) && propositionAccepted === 'RESUME',
    propositionIsAccepted: !R.isNil(propositionAccepted) && R.includes('CONFIRM')(propositionAccepted),
    propositionIsNotAccepted: !R.isNil(propositionAccepted) && propositionAccepted === 'CANCEL',
    propositionIsOverPriced: !R.isNil(propositionAccepted) && propositionAccepted === 'CONFIRM_OVERPRICING',
    medicalCheckResponseIsOk: !R.isNil(medicalCheckResponse) && medicalCheckResponse,
    medicalCheckResponseIsKo: !R.isNil(medicalCheckResponse) && !medicalCheckResponse,
    medicalCheckResponseIsDefined: !R.isNil(medicalCheckResponse),
    medicalFeedbackFileIsDefined: !R.isNil(medicalFeedbackFile),
    declarativeAnnexeFileIsDefined: !R.isNil(declarativeAnnexeFile),
    outstandingAnnexeFileIsDefined: !R.isNil(outstandingAnnexeFile)
  };

  v = R.assoc('mustUploadMedicalFeedback', v.propositionIsAccepted && v.medicalCheckResponseIsOk, v);
  return v;
};

const enhance = compose(
  withRouter,
  withForm,
  withHandlers({
    goPreviousStep: ({ history, backUrl }) => () => history.push(backUrl),
    onNext: ({ form, setDecision, saveOverPricedRate, goNextStep, setCurrentOfferType }) => () => {
      saveOverPricedRate(parseFloat(form.values().overPricedRate));
      setDecision(status(form.values()));
      setCurrentOfferType();
      goNextStep();
    },
    isOnNextEnabled: ({ form, outstandingAnnexe }) => () => {

      const {
        propositionIsAccepted,
        propositionIsOverPriced,
        outstandingAnnexeFileIsDefined,
        medicalCheckResponseIsDefined,
        medicalFeedbackFileIsDefined,
      } = status(form.values());

      const { overPricedRate } = form.values();

      return libs.checkPendingProposition({
        propositionIsAccepted,
        propositionIsOverPriced,
        outstandingAnnexeFileIsDefined,
        medicalCheckResponseIsDefined,
        medicalFeedbackFileIsDefined,
        outstandingAnnexe,
        overPricedRate
      });
    },
    resetError: ({ error, setError }) => () => {
      if (error) setError(false);
    },
  }),
  withProps(
    ({ form }) => ({
      status: status(form.values()),
      showUploadFields: !R.isNil(form.values().propositionAccepted) && R.includes('CONFIRM')(form.values().propositionAccepted)
    })
  )
);

const PendingPropositionStep = enhance(
  ({
    form,
    goPreviousStep,
    isOnNextEnabled,
    onNext,
    medicalCheck,
    declarativeAnnexe,
    outstandingAnnexe,
    // propositionStatus,
    showUploadFields,
    status,
    resetError
  }) => {
    return <React.Fragment>
      <FieldTitle
        text="Ce contrat est en attente car..."
        onBack={goPreviousStep}
      />
      <div>
        <FieldTitle
          showCcn={false}
          text="Réponse du client en attente"
        />

        {form.manageField('propositionAccepted', { defaultValue: null })(
          <Select
            options={
              R.ifElse(
                () => medicalCheck,
                R.compose(
                  R.append({ title: 'Fermer la proposition car refus du client', code: 'CANCEL' }),
                  R.append({ title: 'Finaliser la proposition avec surtarification et télécharger l\'acceptation médicale', code: 'CONFIRM_OVERPRICING' }),
                  R.append({ title: 'Finaliser la proposition et télécharger l\'acceptation médicale', code: 'CONFIRM' }),
                  R.when(
                    R.always(process.env.NODE_ENV === 'development'),
                    R.append({ title: '[DEV] Reprendre la proposition sauvegardée', code: 'RESUME' })
                  )
                ),
                R.compose(
                  R.append({ title: 'Refus du client', code: 'CANCEL' }),
                  R.append({ title: 'Finaliser la proposition', code: 'CONFIRM' }),
                  R.append({ title: 'Reprendre la proposition', code: 'RESUME' })
                )
              )([])
            }
            labelKey="title"
            valueKey="code"
            simpleValue={true}
          />
        )}
      </div>

      {
        !status.propositionIsNotAccepted && medicalCheck &&
        <div className={classNames({ 'pending-proposition-hide-upload-fields': !showUploadFields })}>
          <FieldTitle
            showCcn={false}
            text="Quelle est la réponse du médecin ?"
          />

          {form.manageField('medicalCheckResponse', { defaultValue: null })(
            <Select
              options={[{ title: 'Accord du médecin', code: true }, { title: 'Refus du médecin', code: false }]}
              labelKey="title"
              valueKey="code"
              simpleValue={true}
            />
          )}

          <div>
            <FieldTitle
              showCcn={false}
              text="Joindre la réponse du médecin"
            />

            {form.manageField('medicalFeedbackFile', { defaultValue: null })(
              <UploadButton
                file={form.values().medicalFeedbackFile}
                style={{ maxWidth: '250px', textAlign: 'center', }}
              />
            )}
          </div>

          {<div id="rates" style={{ display: status.propositionIsOverPriced ? 'block' : 'none' }}>
            <span className="rate-label">Surtarification :</span>

            {form.manageField('overPricedRate', {
              isValid: v => validations.rateIsValid(v),
              isUpdated: () => resetError(),
              defaultValue: 0,
              styleOnNotValid: { borderColor: 'orange' },
              cssDisplay: 'inline-block'
            })(
              <TextInput type="number" min="0" max="200" placeholder="Taux" size="small" style={{ width: '80px', textAlign: 'center', display: 'inline-block', marginRight: '5px' }}/>
            )}
            <span>%</span>
          </div>}
        </div>
      }

      {
        !R.includes(outstandingAnnexe, ['with', 'without']) && declarativeAnnexe === 'with' && !status.resumeProposition && !status.propositionIsNotAccepted &&
        <div className={classNames({ 'pending-proposition-hide-upload-fields': !showUploadFields })}>
          <FieldTitle
            showCcn={false}
            text="Annexe déclarative manquante"
          />

          {form.manageField('declarativeAnnexeFile', { defaultValue: null })(
            <UploadButton
              file={form.values().declarativeAnnexeFile}
              style={{ maxWidth: '250px', textAlign: 'center' }}
              disabled={true}
            />
          )}
        </div>
      }

      {R.includes(outstandingAnnexe, ['with', 'without']) &&
      <div
        className={classNames({ 'pending-proposition-hide-upload-fields': !showUploadFields })}
        style={{ marginTop: '60px' }}
      >
        <FieldTitle
          text="Document d'encours"
          showCcn={false}
        />

        {form.manageField('outstandingAnnexeFile', { defaultValue: null })(
          <UploadButton
            file={form.values().outstandingAnnexeFile}
            style={{ maxWidth: '250px', textAlign: 'center' }}
          />
        )}
      </div>}
      <BtnNext disabled={!isOnNextEnabled()} onClick={onNext} title={'Suivant'}/>
    </React.Fragment>;
  }
);

export default connect(
  (state) => {
    const data = R.pathOr({}, ['proposition', 'data'])(state);
    const v = k => R.path(k.split('.'), data);

    return {
      backUrl: `/selectionnerproposition?siren=${v('siren')}`,
      medicalCheck: v('medicalCheck'),
      declarativeAnnexe: v('declarativeAnnexe'),
      outstandingAnnexe: v('outstandingAnnexe'),
      propositionStatus: v('status'),
      userRole: v('userRole')
    };
  },
  (dispatch) => ({
    setDecision: value => dispatch(propositionSetDecision(value)),
    saveOverPricedRate: overPricedRate => dispatch(propositionSaveOverPricedRate(overPricedRate)),
    setCurrentOfferType: () => dispatch(propositionSetCurrentOfferType('PREVOYANCE')),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(PendingPropositionStep));

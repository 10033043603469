import { Button } from '../../../../uikit/UIKit';
import * as R from 'ramda';
import React from 'react';

const SignatureButton = ({
  onClick,
  title,
  disabled = false
}) => {
  return <Button
    onClick={onClick}
    style={{ padding: '19px 10px' }}
    disabled={disabled}
  >
    {title}
  </Button>;
};

const NextButtons = ({
  duplicata,
  userRoleIs,
  withDeclarativeAnnexe,
  makeDuplicata,
  sendManualMode,
  sendElectronicMode,
  allowSignatureDelay,
  offerType,
  offerTypes,
  onNext,
  disabled
}) => {
  const contractHasBothOffers = R.both(
    R.includes('SANTE'),
    R.includes('PREVOYANCE')
  )(offerTypes);

  if (offerType === 'SANTE' && contractHasBothOffers) { // On passe au parcours prévoyance
    return <SignatureButton
      title="Proposer l'offre Prévoyance"
      onClick={onNext}
      disabled={disabled}
    />;
  }

  return <div>
    {duplicata ?
      <SignatureButton onClick={makeDuplicata} title={'Générer un duplicata'}/>
      :
      <div className="documents-step-actions">
        <SignatureButton
          onClick={sendManualMode}
          title={'Signature papier'}
          disabled={disabled}
        />

        {(userRoleIs('groupe_adhesion') || !withDeclarativeAnnexe) && <>
          <SignatureButton
            onClick={sendElectronicMode}
            title={'Signature électronique'}
            disabled={disabled}
          />

          {allowSignatureDelay && <SignatureButton
            onClick={() => sendElectronicMode('delayed')}
            title={'Signature DEM TEL 24H'}
            disabled={disabled}
          />}
        </>}

      </div>
    }
  </div>;
};

export default NextButtons;
